type SendGridContacts = {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  messageText: string;
};

type SendGridSubscriptions = {
  email: string;
  name: string;
};

export const saveSubscriptionsToSendGrid = ({
  email,
  name,
}: SendGridSubscriptions) => {
  let spaceIndex = name.indexOf(" ");
  let first_name = name.trim().slice(0, spaceIndex);
  let last_name = name.trim().slice(spaceIndex + 1);
  let path = "/.netlify/functions/sendgridNewsLetter";

  const data = {
    email,
    first_name,
    last_name,
  };

  // console.log("data sent successfully", data);

  fetch(path, {
    method: "POST",
    body: JSON.stringify(data),
  });
};

export const saveContactsToSendGrid = ({
  first_name,
  last_name,
  email,
  phone_number,
  messageText,
}: SendGridContacts) => {
  let path = "/.netlify/functions/sendgridContactUs";

  const data = {
    first_name,
    last_name,
    email,
    phone_number,
    messageText,
  };

  // console.log("data sent successfully", data);

  fetch(path, {
    method: "POST",
    body: JSON.stringify(data),
  });
};
