import styled from "styled-components";
import { AppButton } from "../common";
import { Colors, Devices } from "../variables";

export const PageTitle = styled("h1")`
  font-size: 2rem;
  font-weight: 600;
  color: ${Colors.ezBlack};
  margin-bottom: 3.125rem;
  margin-top: 2.5rem;

  /* >= 992px; */
  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    font-size: 4rem;
  }
`;

export const ContactContainer = styled("div")`
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
  width: 100%;


  /* >= 992px; */
  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    flex-direction: row;
    // margin-bottom: 27.25rem;
  }
`;

export const FormWrapper = styled("div")`
  justify-content: center;

  /* >= 992px; */
  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    flex-direction: column;
    width: 75%;
    margin-right: 2rem;
  }

  form {
    width: 100%;

    input,
    textarea {
      border-radius: unset;
      border: 1px solid ${Colors.ezBlackHalf};
    }

    textarea {
      height: 9.5rem;
    }
  }

  .form-control {
    padding: 30px 22px;
    font-size: 14px;
    color: ${Colors.solidGray};

    width: 100%;

    /* >= 576px; */
    @media all and (min-width: ${Devices.bootstrapMobileBreakpoint}) {
      width: 545px;
    }

    /* >= 992px; */
    @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
      width: 600px;
    }
  }
`;

export const FormTitle = styled("h2")`
  font-size: 1.5rem;
  /* >= 992px; */
  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    display: initial;
    color: ${Colors.ezBlack};

    font-weight: 500;
    margin-bottom: 2.375rem;
  }
`;

export const Required = styled("p")`
  font-size: .8rem;
  color: #666;
`

export const InfoWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3.5rem;

  & > div:last-child {
    display: none;
  }

  /* >= 992px; */
  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    margin-top: 0;

    & > div:last-child {
      display: initial;
    }
  }
  @media all and (min-width: 1201px) {
    margin-top: 3.5rem;
  }
`;

export const SingleInfo = styled("div")`
  margin-bottom: 1.75rem;
  width: 100%;
`;

export const InfoTitle = styled("h2")`
  font-weight: 500;
  font-size: 1.5rem;
  color: ${Colors.ezBlack};
  margin-top: .5rem;
  margin-bottom: .5rem;
`;

export const InfoContent = styled("a")`
  display: block;
  color: ${Colors.solidGray};
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
`;

export const InfoContentPhone = styled("a")`
  display: block;
  color: ${Colors.solidGray};
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
`;

export const SubmitButton = styled(AppButton)`
  width: 100%;
`;

export const SocialIconsWrapper = styled("div")`
  display: flex;
  margin-top: 1.25rem;
`;

export const LinkWrapper = styled("div")`
  display: flex;

  &:not(:first-child) {
    margin-left: 2.5rem;
  }
`;

export const LinkSocial = styled("a")`
  color: ${Colors.ezBlack};

  span {
    border-bottom: 1px solid transparent;
    padding-bottom: 2px;
  }

  text-decoration: unset;
  &:hover {
    text-decoration: unset;
  }
  &:focus {
    span {
      border-bottom: 1px solid ${Colors.ezBlack};
    }
    outline: none;
  }
  span:hover {
    color: ${Colors.ezBlackHalf} !important;
  }
`;
