import React, { useEffect, useRef, useState } from "react";
import { RespContainer } from "../styles/common";
import {
  AvForm,
  AvInput,
  AvGroup,
  AvFeedback,
} from "availity-reactstrap-validation";
import { GlobalOptions } from "../shared/interfaces/graphql.interface";
import { FontelloIcon } from "../styles/CommonComponents";
import * as Contact from "../styles/ContactPage/StylingContactPage";
import {
  handleNetlifySubmit,
  pushEventIntoDataLayer,
} from "../components/helpers/helpers";
import { saveContactsToSendGrid } from "../components/helpers/sendgrid_helper";
import { InlineWidget } from "react-calendly";

interface PageDataProps {
  pageContext: { data: any };
}

interface PageContext {
  title: string;
  content: string;
  globalOptions: GlobalOptions;
}

interface NetlifyStatesType {
  first_name?: string;
  last_name?: string;
  email?: string;
  phone_number?: string;
  messageText?: string;
}

const contact: React.FC<PageDataProps> = ({ pageContext }) => {
  let pageContextData: PageContext = pageContext.data;

  const netlifyForm = useRef(null);
  const [netlifyStates, setNetlifyStates] = useState<NetlifyStatesType>({});

  const ValidSubmit = () => {
    handleNetlifySubmit(netlifyForm, netlifyStates);
    saveContactsToSendGrid({
      first_name: netlifyStates.first_name || "",
      last_name: netlifyStates.last_name || "",
      email: netlifyStates.email || "",
      phone_number: netlifyStates.phone_number || "",
      messageText: netlifyStates.messageText || "",
    });
    pushEventIntoDataLayer("contactSuccess");
  };

  const SocialMediaIconNames = {
    facebook: "facebook-squared",
    linkedin: "linkedin-squared",
    twitter: "twitter",
  };

  const handleChange = (e) =>
    setNetlifyStates((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));

    const isSSR = typeof window === "undefined";

  const getSize = () => {
    if (typeof window !== "undefined") {
      const windowWidth = window.innerWidth;

      return windowWidth <= 768
        ? { height: "1100px", width: "auto" }
        : windowWidth <= 992
        ? { height: "1100px", width: "auto" }
        : windowWidth <= 1200
        ? { height: "1100px", width: "600px" }
        : windowWidth <= 1440
        ? { height: "1100px", width: "800px" }
        : { height: "800px", width: "1000px" };
    }
  };

  const [calendlySize, setCalendlySize] = useState(getSize());

  useEffect(() => {
    const handleResize = () => {
      setCalendlySize(getSize());
    };

    window.addEventListener("resize", handleResize);

    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <RespContainer>
      {/* <PageTitle dangerouslySetInnerHTML={{ __html: pageContextData.title }} /> */}
      <Contact.PageTitle dangerouslySetInnerHTML={{ __html: "Contact Us" }} />

      <Contact.ContactContainer>
        <Contact.FormWrapper>
          {!isSSR &&(
          <InlineWidget
            styles={{
              height: calendlySize?.height,
              width: calendlySize?.width,
            }}
            url="https://calendly.com/ez-digital/30min"
          />
          )}
        </Contact.FormWrapper>

        <Contact.InfoWrapper>
          <Contact.SingleInfo>
            <Contact.FormTitle>Send us a message</Contact.FormTitle>
            <Contact.Required>
              Fields marked with * are required
            </Contact.Required>
            <AvForm
              onValidSubmit={ValidSubmit}
              // onInvalidSubmit={InvalidSubmit}
              method="post"
              netlify-honeypot="bot-field"
              data-netlify="true"
              name="contact"
              ref={netlifyForm}
            >
              <AvInput type="hidden" name="bot-field" />
              <AvInput type="hidden" name="form-name" value="contact" />

              <AvGroup>
                <AvInput
                  name="first_name"
                  placeholder="Your First name *"
                  required
                  onChange={handleChange}
                />
                <AvFeedback>First Name is required</AvFeedback>
              </AvGroup>

              <AvGroup>
                <AvInput
                  name="last_name"
                  placeholder="Your Last name"
                  onChange={handleChange}
                />
              </AvGroup>

              <AvGroup>
                <AvInput
                  onChange={handleChange}
                  name="email"
                  placeholder="Your e-mail *"
                  type="email"
                  required
                />
                <AvFeedback>Email is required</AvFeedback>
              </AvGroup>

              <AvGroup>
                <AvInput
                  onChange={(e) =>
                    setNetlifyStates((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }))
                  }
                  name="phone_number"
                  placeholder="Phone Number *"
                  type="tel"
                  required
                />
                <AvFeedback>Phone is required</AvFeedback>
              </AvGroup>

              <AvGroup>
                <AvInput
                  onChange={(e) =>
                    setNetlifyStates((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }))
                  }
                  id="messageText"
                  name="messageText"
                  type="textarea"
                  placeholder="Message *"
                  required
                />
                <AvFeedback>
                  Please tell us the reason for your inquiry
                </AvFeedback>
              </AvGroup>

              <Contact.SubmitButton>Send Message</Contact.SubmitButton>
            </AvForm>
          </Contact.SingleInfo>
          <Contact.SingleInfo>
            <Contact.InfoTitle>Get in touch us</Contact.InfoTitle>
            <Contact.InfoContent href="mailto:info@ez-digital.com">
              info@ez-digital.com
            </Contact.InfoContent>
            <Contact.InfoContentPhone href={`tel:+1(323)451-1516`}>
              +1 (323) 451-1516
            </Contact.InfoContentPhone>
          </Contact.SingleInfo>

          <Contact.SingleInfo>
            <Contact.InfoTitle>Follow us</Contact.InfoTitle>
            <Contact.SocialIconsWrapper>
              {pageContextData?.globalOptions &&
                Object.entries(
                  pageContextData?.globalOptions?.company_information
                    .social_links
                ).map((sl, idx) => (
                  <Contact.LinkWrapper key={`social-${idx}`}>
                    <Contact.LinkSocial
                      href={sl[1]}
                      rel={"noopener,noreferrer"}
                      aria-label={sl[0]}
                    >
                      <FontelloIcon icon={SocialMediaIconNames[sl[0]]} />
                    </Contact.LinkSocial>
                  </Contact.LinkWrapper>
                ))}
            </Contact.SocialIconsWrapper>
          </Contact.SingleInfo>
        </Contact.InfoWrapper>
      </Contact.ContactContainer>
    </RespContainer>
  );
};

export default contact;